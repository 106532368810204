@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
b,
strong {
  font-weight: bold;
}

/* .google-div {
  position: absolute;
  top: 0.5rem;
  right: 0.6rem;
} */

.goog-te-combo {
  border: 1px solid #d9e1e7;
  border-radius: 0.4rem;
  padding: 0.4rem;
  width: 11rem !important;
}

.goog-te-gadget .goog-te-combo:hover {
  border-color: #0d9aac;
}

body {
  position: static !important;
  top: 0px !important;
}
/* .skiptranslate {
  display: none;
}
.goog-gt-vt {
  display: none;
} */
.goog-te-gadget > span {
  display: none;
}
.goog-te-gadget {
  display: block !important;
  font-size: 0px !important;
  /* color: transparent !important; */
}

.goog-te-gadget .goog-te-combo {
  font-weight: 700;
  font-family: DM Sans;
  /* font-style: italic; */
  color: #161616;
  font-size: 0.9rem;
}

#goog-gt-tt {
  display: none !important;
}

/* .goog-te-gadget {
  font-size: 0px;
} */

iframe {
  display: none;
}

iframe #webpack-dev-server-client-overlay {
  display: none;
}
/* 
.VIpgJd-ZVi9od-ORHb-OEVmcd{
    display: none;
} */
/* iframe{
    display: none;
} */
.nav-item {
  transition: background-color 0.3s, color 0.3s;
}
.nav-item:hover {
  background-color: #ffffff;
  color: #000000;
}

/* CSS for animation */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
